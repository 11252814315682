import React from 'react'
import { ClipLoader } from 'react-spinners'

type spinnerProps = {
  extraClass?: string
}

const Spinner = ({ extraClass }: spinnerProps): JSX.Element => (
  <div className={`spinner ${extraClass}`}>
    <ClipLoader loading={true} size={18} />
  </div>
)

export default Spinner
